<template>
  <div class="common-chart">
    <div class="hearder-container" v-if="isShowHeader">
      <div class="zuo-yiji">
        <!-- <img src="@/assets/images/charts/zuo-yiji.png" alt="" /> -->
        <img
          class="title_bg"
          src="@/assets/images/charts/title_bg.png"
          alt=""
        />
        <img class="title_dot" src="@/assets/images/charts/dot.png" alt="" />
      </div>
      <div class="you-yiji">
        <img src="@/assets/images/charts/you-yiji.png" alt="" />
      </div>
      <div class="text-container">
        <span>{{ title }}</span>
      </div>
      <div class="hearder-border"></div>
      <div class="echart-change-btn" v-if="showChangeBtn">
        <div
          class="btn"
          :class="{ 'current-btn': chartType == index }"
          @click="showChartType(index)"
          v-for="(item, index) in tabsList"
          :key="index"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="bottom-arrow">
      <div class="arrow-img-container">
        <img src="@/assets/images/charts/leftarrow.png" alt="" />
      </div>
      <div class="arrow-img-container">
        <img src="@/assets/images/charts/rightarrow.png" alt="" />
      </div>
    </div>
    <div class="echart-container">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    isShowHeader: {
      type: Boolean,
      default: true
    },
    showChangeBtn: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    tabsList: {
      type: Array,
      default: function () {
        return [
          { name: '新能源汽车与智能网联', value: '新能源汽车与智能网联' },
          { name: '半导体与集成电路', value: '半导体与集成电路' },
          { name: '生物医药', value: '生物医药' },
          { name: '新一代信息技术', value: '新一代信息技术' }
        ]
      }
    }
  },
  data () {
    return {
      chartType: 0
    }
  },
  mounted () {},
  methods: {
    showChartType (chartType) {
      this.chartType = chartType
      console.log('chartType :>> ', chartType)
      if (chartType == '1') {
        this.$emit('showChartType', chartType)
      } else {
        this.$emit('showChartType', chartType)
      }
    },
    selectTopTabs (val) {
      console.log('sonval', val)
      this.$emit('selectTopTabs', val)
    }
  }
}
</script>
<style lang="less" scoped>
.text-container {
  span {
    opacity: 1;
  }
}
.echart-change-btn {
  position: absolute;
  width: auto;
  height: 2.0625rem;
  bottom: 0;
  right: 1.875rem;
  display: flex;
  .btn {
    // width: 5rem;
    height: 100%;
    font-size: 0.875rem;
    // font-family: Source Han Sans CN;
    font-weight: 400;
    color: #d0deee;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0 0.9375rem /* 15/16 */ 0.75rem /* 12/16 */;
    // margin-right: 1.5rem /* 24/16 */;
    &.current-btn {
      background: linear-gradient(0deg, #149afe 0%, rgba(20, 154, 254, 0) 100%);
      // font-family: Source Han Sans CN;
      font-weight: bold;
      color: #149afe;
    }
  }
}
</style>
