<template>
  <div class="IndustryMap">
    <el-container class="container">
      <el-aside class="aside" style="width:442px">
        <chart-common :title="'产业地图'">
          <div class="content">
            <div class="tabBar">
              <div
                :class="
                  selectedType == 0 ? 'itemOne actived_itemOne' : 'itemOne'
                "
                @click="selectItem(0)"
              >
                <img
                  src="../assets/images/industryMap/left/x-di@2x.png"
                  alt=""
                />
                <span>用地信息</span>
              </div>
              <div
                :class="
                  selectedType == 1 ? 'itemOne actived_itemOne' : 'itemOne'
                "
                @click="selectItem(1)"
              >
                <img
                  src="../assets/images/industryMap/left/x-fang@2x.png"
                  alt=""
                />
                <span>用房信息</span>
              </div>
              <div
                :class="
                  selectedType == 2 ? 'itemOne actived_itemOne' : 'itemOne'
                "
                @click="selectItem(2)"
              >
                <img
                  src="../assets/images/industryMap/left/x-di@2x.png"
                  alt=""
                />
                <span>企业信息</span>
              </div>
            </div>
            <div class="itemList" v-if="selectedType == 0 || selectedType == 1">
              <ul>
                <li v-for="(item, index) in itemList" :key="index">
                  <div class="title">
                    <img
                      src="../assets/images/industryMap/left/03@2x.png"
                      alt=""
                    />
                    <span>{{ item.name }}</span>
                  </div>
                  <p>{{ item.value }}</p>
                </li>
              </ul>
            </div>
            <div class="enterpriseInfoList" v-else>
              <el-collapse accordion>
                <el-collapse-item>
                  <template slot="title">
                    <div class="infoTitle">
                      <img
                        src="../assets/images/industryMap/left/erji@2x.png"
                        alt=""
                      />
                      <span>企业1</span>
                    </div>
                  </template>
                  <div class="itemList">
                    <ul>
                      <li
                        v-for="(item, index) in enterpriseInfoList"
                        :key="index"
                      >
                        <div class="title">
                          <img
                            src="../assets/images/industryMap/left/03@2x.png"
                            alt=""
                          />
                          <span>{{ item.name }}</span>
                        </div>
                        <p>{{ item.value }}</p>
                      </li>
                    </ul>
                  </div>
                </el-collapse-item>
                <el-collapse-item>
                  <template slot="title">
                    <div class="infoTitle">
                      <img
                        src="../assets/images/industryMap/left/erji@2x.png"
                        alt=""
                      />
                      <span>企业2</span>
                    </div>
                  </template>
                  <div class="itemList">
                    <ul>
                      <li
                        v-for="(item, index) in enterpriseInfoList2"
                        :key="index"
                      >
                        <div class="title">
                          <img
                            src="../assets/images/industryMap/left/03@2x.png"
                            alt=""
                          />
                          <span>{{ item.name }}</span>
                        </div>
                        <p>{{ item.value }}</p>
                      </li>
                    </ul>
                  </div>
                </el-collapse-item>
                <el-collapse-item>
                  <template slot="title">
                    <div class="infoTitle">
                      <img
                        src="../assets/images/industryMap/left/erji@2x.png"
                        alt=""
                      />
                      <span>企业3</span>
                    </div>
                  </template>
                  <div class="itemList">
                    <ul>
                      <li
                        v-for="(item, index) in enterpriseInfoList2"
                        :key="index"
                      >
                        <div class="title">
                          <img
                            src="../assets/images/industryMap/left/03@2x.png"
                            alt=""
                          />
                          <span>{{ item.name }}</span>
                        </div>
                        <p>{{ item.value }}</p>
                      </li>
                    </ul>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
          </div>
        </chart-common>
      </el-aside>
      <el-main class="main"><Map ref="map" :type="industryType"></Map></el-main>
    </el-container>
  </div>
</template>
<script>
import Map from '@/components/map'
import chartCommon from '@/components/chartCommon/index'
export default {
  components: {
    Map,
    chartCommon
  },
  data () {
    return {
      industryType: '0',
      selectedType: 2,
      itemList: [],
      landList: [
        { name: '所属街道', value: '龙城街道' },
        { name: '所属社区', value: '龙城街道' },
        { name: '网格编号', value: '55213456987995566' },
        { name: '网格面积', value: '5642475' },
        { name: '建筑物数量（栋）', value: '8657' },
        { name: '建筑面积', value: '235675' },
        { name: '现状容积率', value: '86.23%' },
        { name: '开发强度', value: '235675' },
        { name: '确权用地面积', value: '235675' },
        { name: '确权比例', value: '72.32%' },
        { name: '未确权面积', value: '235675' },
        { name: '宗地号', value: '2345687894564z' },
        { name: '宗地数目', value: '8944' },
        { name: '合同类型', value: '2345687894564z' }
      ],
      enterpriseInfoList: [
        { name: '统一社会信用代码', value: '龙城街道' },
        { name: '企业类型', value: '龙城街道' },
        { name: '地址', value: '55213456987995566' },
        { name: '街道', value: '5642475' },
        { name: '参保人数', value: '8657' },
        { name: '认缴资本总额', value: '235675' },
        { name: '注册状态', value: '86.23%' },
        { name: '行业类别', value: '235675' },
        { name: '成立日期', value: '235675' },
        { name: '法定代表人', value: '72.32%' },
        { name: '联系电话', value: '235675' },
        { name: '登记机关', value: '2345687894564z' }
      ],
      enterpriseInfoList2: [
        { name: '统一社会信用代码', value: '龙城街道' },
        { name: '企业类型', value: '龙城街道' },
        { name: '地址', value: '55213456987995566' },
        { name: '街道', value: '5642475' },
        { name: '参保人数', value: '8657' },
        { name: '认缴资本总额', value: '235675' },
        { name: '注册状态', value: '86.23%' },
        { name: '行业类别', value: '235675' },
        { name: '成立日期', value: '235675' },
        { name: '法定代表人', value: '72.32%' },
        { name: '联系电话', value: '235675' },
        { name: '登记机关', value: '2345687894564z' },
        { name: '产值(亿元)', value: '2345687894564z' },
        { name: '上一年税收金额(万元)', value: '2345687894564z' },
        { name: '专利申请数(件)', value: '2345687894564z' },
        { name: '发明专利(件)', value: '2345687894564z' },
        { name: '外观专利数(件)', value: '2345687894564z' },
        { name: '实用新型专利数(件)', value: '2345687894564z' },
        { name: '企业融资数(次)', value: '2345687894564z' },
        { name: '企业融资总金额(万元)', value: '2345687894564z' },
        { name: '企业对外融资数(次)', value: '2345687894564z' },
        { name: '企业对外融资总金额(万元)', value: '2345687894564z' }
      ],
      housingList: [
        { name: '楼栋编码', value: '86544551223332223' },
        { name: '所属地块编号', value: '86544551223332223' },
        { name: '所属园区', value: '5544557855468784' },
        { name: '园区编码', value: '5544557855468784' },
        { name: '地址', value: '深圳市龙岗区天安数码城B栋' },
        { name: '坐标（x,y）', value: '312,751' },
        { name: '用地类型', value: '商业用地' },
        { name: '用地编码', value: '5544557855468784' },
        { name: '现状载体类型', value: '商业用地' },
        { name: '现状使用用途', value: '商业用地' },
        { name: '建筑基地面积（m²）', value: '312312' },
        { name: '厂房基底面积分布', value: '32312' },
        { name: '建筑面积（㎡）：', value: '4657' },
        { name: '地上层数：', value: '4' },
        { name: '地下层数：', value: '5' },
        { name: '单层建筑面积（㎡）：', value: '434334' },
        { name: '建成年限（年）：', value: '5' },
        { name: '建筑结构：', value: '商业用地' },
        { name: '地下室：', value: '商业用地' },
        { name: '首层是否有设备：', value: '商业用地' },
        { name: '首层承重（公斤/㎡）：', value: '商业用地' },
        { name: '非首层是否是有设备：', value: '商业用地' },
        { name: '非首层承重（公斤/㎡）：', value: '商业用地' },
        { name: '首层层高（m）：', value: '商业用地' },
        { name: '非首层层高（m）：', value: '商业用地' },
        { name: '首层层高分布：', value: '商业用地' },
        { name: '非首层层高分布：', value: '商业用地' },
        { name: '特殊类型层高（m）：', value: '商业用地' },
        { name: '自用面积（㎡）：', value: '商业用地' },
        { name: '出租面积（㎡）：', value: '商业用地' },
        { name: '租赁模式：', value: '商业用地' },
        { name: '空置面积（㎡）：', value: '商业用地' },
        { name: '空置率（%）：', value: '商业用地' },
        { name: '空置原因：', value: '商业用地' },
        { name: '首层租金（元/月·㎡）：', value: '商业用地' },
        { name: '非首层租金（元/月·㎡）：', value: '商业用地' },
        { name: '加权平均租金（元/月·㎡）：', value: '商业用地' },
        { name: '产权种类：', value: '商业用地' },
        { name: '政府物业（㎡）：', value: '商业用地' },
        { name: '企业物业（㎡）：', value: '商业用地' },
        { name: '私人物业（㎡）：', value: '商业用地' },
        { name: '是否有危化品中间仓：', value: '商业用地' },
        { name: '是否有污水处理设施：', value: '商业用地' },
        { name: '是否有客梯：', value: '商业用地' },
        { name: '货梯个数：', value: '商业用地' },
        { name: '货梯载重(公斤/㎡)：', value: '商业用地' },
        { name: '是否有卸货平台：', value: '商业用地' },
        { name: '是否有地下车库：', value: '商业用地' },
        { name: '产权人：', value: '商业用地' },
        { name: '联系人：', value: '商业用地' },
        { name: '联系电话:', value: '商业用地' }
      ]
    }
  },
  created () {
    this.selectItem(0)
  },
  methods: {
    selectItem (type) {
      this.selectedType = type
      switch (type) {
        case 0:
          this.itemList = this.landList
          break
        case 1:
          this.itemList = this.housingList
          break

        default:
          break
      }
    }
  }
}
</script>
<style lang="less" scoped>
.IndustryMap {
  .container {
    padding: 0 20px;
    margin-top: 20px;
    .aside {
      .content {
        padding: 0 10px;
        margin: 20px 0;
        height: 800px;
        overflow-y: scroll;
        .tabBar {
          display: flex;
          height: 40px;
          line-height: 40px;
          background: url('../assets/images/industryMap/left/02@2x.png');
          // padding: 0 10px;
          .itemOne {
            align-items: center;
            padding: 0 23px;
            width: 33.3%;
            text-align: center;
            cursor: pointer;

            img {
              width: 20px;
              height: 18px;
              margin-right: 6px;
            }
            span {
              font-size: 16px;
              font-family: Source Han Sans CN;
              font-weight: normal;

              color: #d0deee;
            }
          }
          .actived_itemOne {
            background: url('../assets/images/industryMap/left/ditu@2x.png')
              no-repeat;
            background-size: cover;
            background-position-x: center;
            background-position-y: center;
            span {
              font-size: 16px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #ffffff;
            }
          }
        }
        .itemList {
          margin-top: 13px;
          padding-left: 10px;
          ul {
            display: flex;
            flex-wrap: wrap;
            li {
              width: 50%;
              margin-top: 19px;
              .title {
                position: relative;
                img {
                  height: 10px;
                }
                span {
                  font-size: 14px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #d0deee;
                  position: absolute;
                  top: 2px;
                  left: 3px;
                }
              }
              p {
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #ffffff;
                margin-top: 5px;
                padding-left: 2px;
              }
            }
          }
        }
        .enterpriseInfoList {
          margin-top: 32px;
          .infoTitle {
            position: relative;
            padding-bottom: 9px;
            img {
              height: 100%;
              width: 100%;
            }
            span {
              position: absolute;
              top: 0px;
              left: 10px;
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #3be8ff;
            }
          }
        }
      }
    }
  }
}
.main {
  padding: 0;
  margin-left: 10px;
}
</style>
